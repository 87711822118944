.thumbnail-container {
    position: relative;
    width: 320px;
    height: 180px;
  }
  
  .thumbnail-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
    transition: opacity 0.2s ease-in-out;
  }
  
  .thumbnail-container:hover .thumbnail-overlay {
    opacity: 1;
  }
  
  .youtube-button {
    background-color: #c4302b;
    width: 60px;
    height: 40px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
  
  .thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .play-button::before {
    position: absolute;
    top: 35%;
    left: 40%;
    font-size: 20px;
  }
  