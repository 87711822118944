.notes-list-container {
    margin-top: 90px;
    min-height: 500px;
}

.gradient-button {
    width: 150px;
    height: 60px;
    border-radius: 5px;
    border: 4px solid #000;
    /* background: linear-gradient(90deg, #9e66c6, #6027e1);  */
    background-color: #FFD500;
    box-shadow: 3px 3px 0px #555, 7px 7px 0px #000;
    color: #000;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    padding: 20px 30px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    position: relative;
  }
  
  .gradient-button .title {
    font-family: Lato, Monteserrat, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 25px;
    font-size: 1em;
    text-transform: uppercase;
  }

  .gradient-button:hover {
    transform: scale(1.1);
  }
  