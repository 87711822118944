.carousel {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  /* padding: 30px 0; */
  z-index: 1;
  margin: 0 auto;
  position: relative;
  background-color: #FF90E8;;
  border-top: 6px solid;
  border-bottom: 6px solid;
}

.cardWrapper {
  display: flex;
  position: relative;
  flex-flow: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 10px;
  width: 30%;
  height: 100%;
  min-height: 469px;
  overflow: hidden;
  border: 5px solid #000;
  border-radius: 7px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.cardWrapper:hover {
  transform: translateY(-10px);
}

.centerCardWrapper {
  width: 30%;
  height: 100%;
  border-color: #ff6f61;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 991px) {
  .carousel {
    justify-content: space-between;
  }

  .cardWrapper {
    width: 46%;
  }

  .centerCardWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .carousel {
    /* background-color: #23a094; */
    /* border-radius: 25px; */
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
    margin: 0 auto;
    width: 100%;
    /* padding: 50px; */
    position: relative;
    text-align: center;
    z-index: 1;
    flex-direction: column;
  }

  .cardWrapper {
    width: 90%;
    margin: 10px 0;
  }
}
