.contact-g-form{
    display: flex;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    
}

.gradient-button-g-form {
    width: 250px;
    height: 60px;
    border-radius: 5px;
    border: 4px solid #000;
    background-color: #FF90E8;
    box-shadow: 3px 3px 0px #555, 7px 7px 0px #000;
    color: #000;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    padding: 20px 30px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    position: relative;
  }

  .gradient-button-g-form:hover {
    transform: scale(1.1);
    color: #000;
    box-shadow: 4px 4px 0px #555, 9px 9px 0px #000;
  }