.footer {
    display: flex;
    flex-direction: row wrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: center;
    width: 100%;
    /* background-color: #141c30; */
    background: linear-gradient(90deg, #FFD500 20px, transparent 1%) center, linear-gradient(#FFD500 20px, transparent 1%) center, #E2442F;
    background-size: 22px 22px;
    padding-top: 20px;
    padding-bottom: 70px; 
    z-index: 1;
    position: relative;
    border-top: 6px solid;
}

.footer-logo {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fffff0;
}

.footer-title {
    padding-left: 7px;
    text-decoration: none;
    font: Lato, sans-serif;
    font-weight: 700;
    font-size: 20px;
    display: inline;
}

.footer-ref {
    display: flex;
    flex-direction: row;
    color: #000;
    font-weight: bold;
    font-size: 12px;
    align-items: center;
}

.f-ref-i {
    padding: 6px;
}

.footer .footer-social-accounts {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    letter-spacing: 1em;
    flex-direction: column;
    color: #000;
    font-weight: bold;
    margin-top: 10px;
}

.footer-social-accounts a {
    color: #000;
    font-weight: bold;
    font-size: 50px;
}

.f-ref-r {
    display: flex;
    flex-direction: column;
}

@media (min-width: 650px) {
    .footer-title {
        display: inline;
    }
    .footer .footer-social-accounts {
        flex-direction: row;
    }
}

@media (min-width: 720px) {
    .f-ref-r{
        flex-direction: row;
    }
}
.logo-text
{
    color: #000;
    font-size: 15px;
}

.footer-contact-us {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 15px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}
.footer-contact-us a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
}
.footer-container {
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, #FFD500 20px, transparent 1%) center, linear-gradient(#FFD500 20px, transparent 1%) center, #E2442F;
    background-size: 22px 22px;
    width: 100%;
    align-items: center;
    justify-content: center;
}