/* General styles */
.landing-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #23a094 20px, transparent 1%) center, linear-gradient(#23a094 20px, transparent 1%) center, #000;
  background-size: 22px 22px;
}

.landing-page-section {
  display: flex;
  flex: 0 0 100%;
  height: 100vh;
  margin-top: 100px;
}

.landing-page-section-content {
  max-width: 800px;
  /* padding: 0 20px; */
  text-align: center;
}

.landing-page-heading {
  font-size: 3rem;
  margin-bottom: 20px;
}

.landing-page-subheading {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

/* Header styles */
.landing-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.landing-page-logo {
  display: flex;
  align-items: center;
}

.landing-page-logo img {
  height: 40px;
}

.landing-page-logo-text {
  font-size: 1.5rem;
  margin-left: 10px;
}

.landing-page-nav {
  display: flex;
}

.landing-page-nav-link {
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.landing-page-nav-link:hover {
  color: #00b8e6;
}