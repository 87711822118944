.flashcards {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 600px;
    margin-top: 85px;
    background: linear-gradient(90deg,#141c3a 20px,transparent 1%) 50%,linear-gradient(#141c3a 20px,transparent 1%) 50%,#ffc740;
    background-size: 22px 22px;
}
.flashcards h1 {
  color:#fff;
}
.fc-carousel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    /* padding: 30px 0; */
    z-index: 1;
    margin: 0 auto;
    position: relative;
  }
  
  .fc-carousel a {
    text-decoration: none;
    text-decoration-color: none;
  }
  
  .fc-topic-container {
    width: 25%;
    height: 250px;
    margin: 20px;
    min-width: 300px;
    border-radius: 5px;
    border: 4px solid #000;
    /* background: linear-gradient(90deg, #9e66c6, #6027e1);  */
    /* background-color: #FFD500; */
    box-shadow: 3px 3px 0px #555, 7px 7px 0px #000;
    color: #000;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    padding: 20px 30px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    position: relative;
  }

  @media only screen and (max-width: 767px) {
    .fc-carousel {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      z-index: 1;
      /* flex-direction: column; */
    }

    /* .fc-topic-container {
      width: 95%;
    } */
  }
  


  .fc-topic-container .title {
    font-size: 20px;
    font-family: "Poppins";
    text-decoration: underline;
  }

  .fc-topic-container .description {
    font-size: 18px;
    font-family: 'Inconsolata', Poppins, serif;
  }