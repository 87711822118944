.card {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Poppins, serif;
  width: 90%;
  height: 100%;
}

.name {
  font-size: 1.4rem;
  margin: 0 0 10px 0;
  font-weight: bold;
  font-family: Poppins, serif;
}

.text {
  font-size: 1.2rem;
  margin: 0 0 20px 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  background-color: #f5f5f5;
  color: #000000;
  font-size: 1.2rem;
  /* padding: 3px 10px; */
  border-radius: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: solid 2px;
}

.reviewHeader {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.rating {
  color : #000;
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Poppins, serif;
}