/* VideoList */
.video-list {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    min-height: 95vh;
  }
  
  /* VideoGroup */
  .video-group {
    margin-bottom: 30px;
  }
  
  .video-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
  }
  
  .video-group-header h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  
  .collapsible-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
  }
  
  .collapsible-button:hover {
    background-color: #e0e0e0;
  }
  
  .collapsible-button span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #333;
    border-radius: 2px;
    position: relative;
    transition: all 0.3s ease-in-out;
  }
  
  .collapsible-button span:first-child {
    transform-origin: center left;
    transform: rotate(45deg);
  }
  
  .collapsible-button span:last-child {
    transform-origin: center right;
    transform: rotate(-45deg);
  }
  
  .collapsible-button.closed span:first-child {
    transform: rotate(0);
  }
  
  .collapsible-button.closed span:last-child {
    transform: rotate(0);
  }
  
  .video-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;
    position: relative;
  }
  
  .selected {
    flex-direction: column;
  }

  .video-container.selected .video-description {
    display: block;
  }
  
  .video-container.selected .video-title {
    font-weight: bold;
  }
  
  .video-description {
    border-bottom: solid 2px;
    padding: 10px;
  }
  
  .video-container .video-title {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
  }

  @media screen and (max-width: 576px) {
    .video-group-header {
      padding: 10px;
    }
  
    .video-group-header h3 {
      font-size: 16px;
    }
  
    .collapsible-button {
      height: 20px;
      width: 20px;
      right: 10px;
    }
  
    .video-container .video-title {
      font-size: 14px;
    }
  
    .video-container .video-description {
      font-size: 12px;
    }

  }
  
  @media screen and (min-width: 675px) {
    .video-container {
        flex-direction: row;
    }
    .selected {
        flex-direction: column;
    }
  }
