.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: #000;
    border-bottom: 4px solid;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  
  .logo {
    display: flex;
    align-items: center;
    margin: 8px;
  }
  
  .logo img {
    height: auto;
    max-width: 100%;
    max-height: 50px;
  }
  
  .logo-text {
    font-size: 1.5rem;
    margin-left: 10px;
  }
  
  .nav {
    display: flex;
  }
  
  .nav-link {
    color: #000;
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 20px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
  
  .nav-link:hover {
    color: #00b8e6;
  }
  
  .login-btn {
    border: none;
    background-color: #ff90e8;
    color: #000;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px 10px;
    border-radius: 5px;
    border: solid 2px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 8px;
  }
  
  .login-btn:hover {
    background-color: #fff;
    color: #00b8e6;
    border: 2px solid #00b8e6;
  }
  #button-sign-out {
    float: right; 
    text-align: center;
    font-size: x-small;
    max-width: 30%;
    border-radius: 5px;
  }


  
  #loggedInUserImage {
    border-radius: 50%;
    max-height: 40px;
    max-width: 40px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
  }
  
  .loggedInUserDetails {
    display: flex;
    align-content: center;
  }
  
  
  .btnClrd {
    cursor: pointer;
    margin: 5px;
    border-radius: 3px;
    text-decoration: none;
    padding: 5px;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    display: inline-block;
  }
  
  .orange {
    color: #555;
  }
  
  .orange:hover {
    color: #000;
  }
  
  .signoutBtn, .homebutton {
    text-align: center;
    font-family: Lato;
    font-weight: bold;
    font-size: 14px;
    border-left: 2px solid #000;
    color: #000;
  }
  
  .homebutton {
    display: none;
  }
  
  .header-left-content .header-title {
    display: none;
  }
  