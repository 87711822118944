.flash-card-page-container {
    display: flex;
    margin-top:85px;
    flex-direction: column;
    background: linear-gradient(90deg,#141c3a 20px,transparent 1%) 50%,linear-gradient(#141c3a 20px,transparent 1%) 50%,#ffc740;
    background-size: 22px 22px;
    font-family: "Inconsolata", serif;
    font-weight: bold;
}
.flash-card-page-container h2 {
  background-color: #141c3a;
  color: #fff;
  width: fit-content;
  margin-left: 10px;
  font-family: "Poppins", "Inconsolata", serif;
}
.decks-container {
    /* margin-top: 150px; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    /* padding: 30px 0; */
    z-index: 1;
    margin: 0 auto;
    position: relative;
}
.flashcard-deck {
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    height: 550px;
    justify-content: space-between;
    width: 340px;
    margin: 10px;
    border: 3px solid;
    box-shadow: 3px 3px 0px #555, 7px 7px 0px #000;
    background-color: #fff;
}
  
  .flashcard {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%;

  }
  
  .title-card h1 {
    font-size: 28px;
    margin-bottom: 8px;
    text-align: center;
  }
  
  .info-card h2 {
    font-size: 18px;
    margin: 5px;
    margin-top: 0px;
    background-color: unset;
    color: #000;
  }  
  .difference-card h2 {
    font-size: 18px;
    margin: 5px;
    margin-top: 0px;
    background-color: unset;
    color: #000;
  }
  
  .mcq-card h2 {
    font-size: 18px;
    margin: 5px;
    margin-top: 0px;
    background-color: unset;
    color: #000;
  }

  .info-card p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0;
    text-align: center;
  }
  
  .difference-card table {
    border-collapse: collapse;
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
  }
  
  .difference-card td {
    border: 2px solid #000;
    padding: 5px;
    text-align: center;
  }
  
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    background-color: black;
    margin-bottom: 0;
  }
  
  button {
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    padding: 6px 10px;
    border: none;
  }
  
  button:hover {
    color: #aaa;
  }

  .fc-btn-disabled {
    color: #555;
  }
  .fc-btn-disabled:hover {
    color: #555;
    cursor: default;
  }

  @media screen and (min-width: 600px) {
    .flashcard-deck {
        border-radius: 10px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        height: 340px;
        justify-content: space-between;
        width: 550px;
        margin: 10px;
        border: 3px solid;
        box-shadow: 3px 3px 0px #555, 7px 7px 0px #000;
    }
  }

  .flashcard-title {
    border-bottom: 2px solid black;
    width: 100%;
    background: #FFD500;
    border-radius: 10px 10px 0px 0px;
  }

  .info-card {
    width: 100%;
  }

  .difference-card {
    width: 100%;
  }
  .mcq-card {
    width: 100%;
  }

  .fc-hint-btn {
    margin: 10px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: 3px solid black;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding: 0;
  }

  .fc-hint-btn:hover {
    color: #fff;
    background-color: #000;
  }

  .fc-hint-box {
    border: 1px solid;
  }

  .fc-hint-box-hidden {
    display: none;
  }

  .fc-mcq-table {
    border-collapse: collapse;
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
  }
  .fc-mcq-table td {
    margin: 10px;
    text-align: center;
  }
  .fc-options {
    border-radius: 5px;
  }
 
  .fc-options:hover {
    cursor: pointer;
    background-color: #7fffd4;

  }

  .fc-right-ans {
    background-color: aquamarine;
  }

  .fc-wrong-ans {
    background-color: tomato;
  }

  .currently-fc-sel {
    display: none;
  }

  .math-eq {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px;
    font-size: 15px;
  }

  .flashcard-deck .fa {
    font-size: 17px;
    margin: 11px;
    text-decoration: none;
    color: #fff;
  }

  .flashcard-deck .fa:hover {
    cursor: pointer;
  }
