.welcome-card-content {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.left-content {
    background-color: #23a094;
    justify-content: center;
    margin: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.right-content {
    /* padding: 50px; */
    margin: 50px;
}

.highlight-container {
    font-size: 1.75em;
    font-family: 'Poppins', serif;
    margin-top: 40px;
    margin-bottom: 60px;
    z-index: 1;
}

.welcome-card {
    
    /* border-radius: 25px; */
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
    margin: 0 auto;
    width: 100%;
    /* padding: 50px; */
    position: relative;
    text-align: center;
    z-index: 1;
  }
  
  .particle-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .welcome-card .particle-canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .welcome-card h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .welcome-card p {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
  
  .welcome-card .typing-effect {
    font-family: 'Poppins', serif;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .gradient-button {
    width: 150px;
    height: 60px;
    border-radius: 5px;
    border: 4px solid #000;
    /* background: linear-gradient(90deg, #9e66c6, #6027e1);  */
    background-color: #FFD500;
    box-shadow: 3px 3px 0px #555, 7px 7px 0px #000;
    color: #000;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    padding: 20px 30px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    position: relative;
  }
  
  .gradient-button .title {
    font-family: Lato, Monteserrat, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 25px;
    font-size: 1em;
    text-transform: uppercase;
  }

  .gradient-button .ratings {
    font-size: 10px;
    font-family: Lato, Montserrat, Helvetica, sans-serif;
}

  .gradient-button .cont-path {
    font-family: Lato, Monteserrat, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: black;
  }
  .gradient-button:hover {
    transform: scale(1.1);
  }
  
  @media screen and (min-width: 768px) {
    .welcome-card-content {
        flex-direction: row;
    }
    .left-content {
      width: 700px;
    }
  }
  
  .highlight-contain, .highlight {
    position: relative;
  }
  
  .highlight-contain {
    display: inline-block;
  }
  .highlight-contain:before {
    content: " ";
    display: block;
    height: 90%;
    width: 100%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: #FF90E8;
    transform: rotate(2deg);
    top: -1px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    /* padding: 10px 3px 3px 10px; */
  }