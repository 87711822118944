.whatsapp-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
  }
  .important-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .whatsapp-button-link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25d366;
    color: #000;
    padding: 10px 20px;
    border-radius: 50px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
  }
  
  .whatsapp-button-link:hover {
    transform: scale(1.05);
  }
  
  .whatsapp-button-icon {
    margin-right: 5px;
    font-size: 24px;
  }
  
  .whatsapp-button-text {
    display: none;
  }

  .fa-whatsapp {
    font-size: 50px;
  }
  
  @media screen and (min-width: 768px) {
    .whatsapp-button-text {
      display: inline-block;
      margin-left: 5px;
    }
    .important-links {
      flex-direction: row;
    }
    .gradient-button {
      margin-left: 20px;
    }
  }
  