/* Modal container */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal content */
  .modal-content {
    background-color: #ffffff;
    width: 400px;
    max-width: 90%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Modal header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Modal close button */
  .modal-close {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #999999;
  }
  
  /* Modal body */
  .modal-body {
    margin-bottom: 20px;
  }
  
  .modal-header h2 {
    font-size: 20px;
    background-color: #fff;
    color: #000;
  }

  .modal-header .fa {
    font-size: 40px;
    margin: 30px;
    color: #000;
    background-color: unset;
  }
  /* Modal overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }  

  .modal-url-box {
    width: 100%;
    border: 2px solid brown;
    height: 30px;
    border-radius: 5px;
  }

  .share-modal-btns i {
    color: black;
  }